// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  list-style: none !important;
  outline: none;
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100vw;
  margin: 0;
}

main {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
}

main > * {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;EACT,qBAAqB;EACrB,8CAA8C;EAC9C,6CAA6C;EAC7C,2BAA2B;EAC3B,aAAa;AACf;;AAEA;;EAEE,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,cAAc;EACd,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":["* {\n  padding: 0;\n  margin: 0;\n  text-decoration: none;\n  -webkit-font-smoothing: antialiased !important;\n  -moz-osx-font-smoothing: grayscale !important;\n  list-style: none !important;\n  outline: none;\n}\n\nhtml,\nbody {\n  height: 100%;\n  margin: 0;\n}\n\nbody {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  max-width: 100vw;\n  margin: 0;\n}\n\nmain {\n  display: flex;\n  flex: 1 1 auto;\n  width: 100%;\n}\n\nmain > * {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
